<template>
    <div class="right_main">
        <!-- 统计图部分 -->
        <div class="details">
            <div class="left_detail">
                <div class="pid">
                    <div class="pid_tip"></div>
                    <div class="pid_name">当月任务情况</div>
                </div>
                <!-- 环形图显示部分 -->
                <div class="annular" ref="an_charts"></div>
            </div>
            <div class="right_detail">
                <div class="pid">
                    <div class="pid_tip"></div>
                    <div class="pid_name">年度任务情况</div>
                </div>
                <div class="pro_step">
                    <div class="sucss_pro">
                        <div class="succ_col">
                            <div class="succ su"></div>
                            <div class="succ_name">完成-续保</div>
                        </div>
                        <el-progress :percentage="renewal" :format="complete" :stroke-width="26" color="#5eca94">
                        </el-progress>
                    </div>
                    <div class="sucss_pro">
                        <div class="succ_col">
                            <div class="succ su1"></div>
                            <div class="succ_name">完成-不续保</div>
                        </div>
                        <el-progress :percentage="non_renewal" :format="no_complete" :stroke-width="26" color="#6bb1bb">
                        </el-progress>
                    </div>
                    <div class="sucss_pro">
                        <div class="succ_col">
                            <div class="succ su2"></div>
                            <div class="succ_name">新增客户</div>
                        </div>
                        <el-progress :percentage="quantity" :format="newly" :stroke-width="26" color="#755fad">
                        </el-progress>
                    </div>
                    <div class="sucss_pro">
                        <div class="succ_col">
                            <div class="succ su3"></div>
                            <div class="succ_name">累计客户</div>
                        </div>
                        <el-progress :percentage="cumul" :format="total" :stroke-width="26" color="#f7cf45">
                        </el-progress>
                    </div>
                </div>
            </div>
        </div>
        <!-- 数据详情部分 -->
        <div class="situation_data">
            <!-- 页面标题  按钮部分 -->
            <div class="top_btn">
                <!-- 页面标题部分 -->
                <div class="page_name">
                    <div class="sig"></div>
                    <div class="sig_name">当月任务情况</div>
                </div>
                <!-- 下拉框部分 -->
                <div class="sel_div">
                    <el-select v-model="type_id" placeholder="公司名" clearable size="mini" @change="initData">
                        <el-option v-for="item in type_arr" :key="item.uid" :label="item.partner_uid" :value="item.uid">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <!--表格部分  -->
            <div class="tab_list">
                <el-table :data="tableData" style="width: 100%"
                    :header-cell-style="{color:'#000000',font:'14px 600',borderBottom:'none'}">
                    <el-table-column prop="uid" align="center" v-if="show=false">
                    </el-table-column>
                    <el-table-column prop="partner_uid" label="公司名" min-width="100">
                    </el-table-column>
                    <el-table-column prop="pcount" label="业务员数量">
                    </el-table-column>
                    <el-table-column prop="untreated" label="未处理(个)">
                    </el-table-column>
                    <el-table-column prop="treated" label="沟通中(个)">
                    </el-table-column>
                    <el-table-column prop="complete" label="完成-续保(个)">
                    </el-table-column>
                    <el-table-column prop="nocomplete" label="完成-不续保(个)">
                    </el-table-column>
                    <el-table-column prop="month_count" label="新增客户量(个)">
                    </el-table-column>
                    <el-table-column prop="customer_count" label="客户总量(个)">
                    </el-table-column>
                </el-table>
            </div>
            <!-- 分页部分 -->
            <div v-if="page_show">
                <Paging :count="count" :initData="initData" @changePage="handle_changePage"></Paging>
            </div>
        </div>
    </div>
</template>

<script>
import Paging from '@/components/Paging';
import { postCensusApi } from '@/api'
export default {
    components: {
        Paging,
    },
    data () {
        return {
            // 登录用户id
            user_id: '',
            // 有没有下级
            lower: '',
            // 表格数据
            tableData: [],
            // 分页
            page: 1,
            count: 0,
            max: 10,
            page_show: false,
            // 筛选数据
            type_id: '',
            type_arr: [],
            // 完成  续保   进度条
            renewal: 0,
            // complete: '',
            // 完成  不续保   进度条
            non_renewal: 0,
            // no_complete: '',
            // 新增客户   进度条
            quantity: 0,
            // newly: '',
            // 累计客户   进度条
            cumul: 0,
            // total: '',
            // 统计图  客户总量
            total_num: 0,
            // 未沟通数量
            connect: 0,
            // 沟通中数量
            connecting: 0,
            // 已完成数量
            finish: 0,
        }
    },
    created () {
        this.initData()
    },
    watch: {
        // 监听分页数量的变化
        count () {
            if (this.count == (this.page - 1) * this.max && this.count != 0) {
                this.page -= 1;
                this.initData()
            }
        },
    },
    methods: {
        // 子组件传过来的当前页数
        handle_changePage (data) {
            this.page = data;
        },
        // 初始化数据
        initData () {
            this.user_id = sessionStorage.getItem('id');
            this.lower = sessionStorage.getItem('lower');   //有没有下级
            postCensusApi({
                page: this.page,
                auth_id: this.user_id,  //用户id
                has_lower: this.lower,  //有没有下级
                lower_id: this.type_id   //下级公司id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.tableData = res.bottom.data;   //表格数据
                    this.count = res.bottom.count;    //分页总数
                    if (this.count <= 10) {
                        this.page_show = false;
                    } else {
                        this.page_show = true;
                    };
                    this.type_arr = res.lower_datas;    //下拉数据
                    // 饼图数据
                    this.total_num = res.left.all;
                    this.connect = res.left.not.count;
                    this.connecting = res.left.in.count;
                    this.finish = res.left.over.count;
                    this.init_annular();
                    this.renewal = res.right.over;
                    this.quantity = res.right.add_count;
                    this.non_renewal = res.right.over_not;
                    this.cumul = res.right.customer_count;
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' });
                }
            })
        },
        complete (percentage) {
            return `${this.renewal}`;
        },
        no_complete (percentage) {
            return `${this.non_renewal}`;
        },
        newly (percentage) {
            return `${this.quantity}`;
        },
        total (percentage) {
            return `${this.cumul}`;
        },
        // 环形统计图数据
        init_annular () {
            let annular_chart = this.$echarts.init(this.$refs.an_charts);
            // 绘制图表
            annular_chart.setOption({
                title: {
                    text: '客户总量',
                    subtext: this.total_num,
                    subtextStyle: {
                        color: '#333333',
                        fontSize: '18',
                    },
                    left: 'center',
                    top: '40%'
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    icon: "circle",
                    bottom: '30%',
                    left: '80%',   //图例组件离容器左侧的距离
                    itemGap: 30,    //设置图例的间距
                },
                series: [
                    {
                        type: 'pie',   //形状为饼图
                        radius: ["50%", "70%"], // 这个属性修改圆环宽度
                        avoidLabelOverlap: false, //是否启用防止标签重叠策略
                        label: {   //饼图图形上的文本标签
                            show: true,
                            position: 'inner',
                            textStyle: {
                                fontWeight: 400,
                                fontSize: 14    //文字的字体大小
                            },
                            formatter: '{d}%'
                        },
                        emphasis: {  //文本标签在中间是否显示 以及显示样式
                            label: {
                                show: false,
                                // fontSize: '16',
                                // fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            { value: this.connect, name: '未沟通', itemStyle: { color: '#6a8be8' } },
                            { value: this.connecting, name: '沟通中', itemStyle: { color: '#f4b33f' } },
                            { value: this.finish, name: '已完成', itemStyle: { color: '#62bea9' } },
                        ]
                    }
                ]
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.right_main {
    width: 100%;
    height: 100%;
    // 统计图部分
    .details {
        width: 100%;
        height: 368px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left_detail {
            width: 48%;
            height: 90%;
            margin-right: 32px;
            background: #ffffff;
            border-radius: 20px;
            padding: 16px 20px;
            .pid {
                display: flex;
                align-items: center;
                .pid_tip {
                    border-radius: 2px;
                    width: 4px;
                    height: 16px;
                    background: #6a8be8;
                    margin-right: 6px;
                }
                .pid_name {
                    font-size: 16px;
                    font-weight: 600;
                    color: #000000;
                }
            }
            .annular {
                width: 100%;
                height: 300px;
                position: relative;
                left: -120px;
                top: -8px;
                // /deep/canvas {
                //     width: 580px !important;
                //     height: 338px !important;
                // }
            }
        }
        .right_detail {
            width: 48%;
            height: 90%;
            background: #ffffff;
            border-radius: 20px;
            padding: 16px 20px;
            .pid {
                display: flex;
                align-items: center;
                .pid_tip {
                    border-radius: 2px;
                    width: 4px;
                    height: 16px;
                    background: #6a8be8;
                    margin-right: 6px;
                }
                .pid_name {
                    font-size: 16px;
                    font-weight: 600;
                    color: #000000;
                }
            }
            .pro_step {
                margin-top: 16px;
                .el-progress {
                    /deep/.el-progress__text {
                        font-size: 14px !important;
                        font-weight: 500 !important;
                        margin-left: 16px;
                    }
                }
                .sucss_pro {
                    margin-bottom: 20px;
                    text-align: left;
                    .succ_col {
                        margin-bottom: 10px;
                        display: flex;
                        align-items: center;
                        .succ {
                            width: 12px;
                            height: 12px;
                            border-radius: 50%;
                            margin-right: 8px;
                        }
                        .su {
                            background: #5eca94;
                        }
                        .su1 {
                            background: #6bb1bb;
                        }
                        .su2 {
                            background: #755fad;
                        }
                        .su3 {
                            background: #f7cf45;
                        }
                        .succ_name {
                            font-size: 14px;
                            font-weight: 400;
                            color: #000000;
                        }
                    }
                }
            }
        }
    }
    // 数据详情部分
    .situation_data {
        width: 100%;
        min-height: 200px;
        margin-top: 32px;
        background: #ffffff;
        border-radius: 20px;
        //  页面标题  按钮部分
        .top_btn {
            display: flex;
            justify-content: space-between;
            // align-items: center;
            padding: 24px;
            border-bottom: 1px solid #e8e9f1;
            // 页面标题部分
            .page_name {
                display: flex;
                align-items: center;
                .sig {
                    border-radius: 2px;
                    width: 4px;
                    height: 16px;
                    background: #6a8be8;
                    margin-right: 6px;
                }
                .sig_name {
                    font-size: 16px;
                    font-weight: 600;
                    color: #000000;
                }
            }
            // 按钮部分
            .sel_div {
                .el-input {
                    border: none;
                    width: 150px;
                    height: 32px;
                    background: #eaf0fe;
                    border-radius: 8px;
                }
                /deep/.el-input__inner {
                    border: none;
                    width: 150px;
                    height: 32px;
                    background: #eaf0fe;
                    border-radius: 8px;
                }
                /deep/input::-webkit-input-placeholder {
                    color: #20252b;
                }
                /deep/input::-moz-input-placeholder {
                    color: #20252b;
                }
                /deep/input::-ms-input-placeholder {
                    color: #20252b;
                }
            }
        }
        // 表格部分
        .tab_list {
            background: #ffffff;
            border-radius: 0 0 20px 20px;
            padding: 20px 40px;
            .el-table {
                border-bottom: none;
            }
            /deep/.el-table td,
            .el-table th.is-leaf {
                border-bottom: none;
            }
            /deep/.el-table--border::after,
            .el-table--group::after,
            .el-table::before {
                background: #ffffff;
            }
        }
    }
}
</style>